import logo from './images/logo_dark.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="logo" alt="logo" />
        <div className="skills">
          <p className="subtext">Mobile app development</p>

          <p className="subtext">Web development</p>
          <p className="subtext">Blockchain development</p>
        </div>
        <a className="contact-button" href={'mailto:arno@itesca.be'}>
          Contact
        </a>
        <a className='terms' href="https://firebasestorage.googleapis.com/v0/b/itesca-6ec97.appspot.com/o/itesca%20algemene%20voorwaarden.pdf?alt=media&token=da076916-6b79-4e90-8fbd-47e0c005ef08">Algemene voorwaarden</a>
      </header>
    </div>
  );
}

export default App;
